(function($) {
  // global settings object
  Drupal.ELB = {
    ui: {
      alt_class: "is-alternate", // global/_variables.scss:$alt,
      landing_class: "is-landing", // front/landing class based on home/mobile-home-formatter
      sticky_class: "is-sticky", // header sticky
      active_class: "js-active", // active states (menus), _variables.scss
      search_class: "is-search", // global/_variables.scss:$search,
      dark_nav_class: "is-dark-nav", // global/_variables.scss:$$dark-nav,
      back_top_duration: 800, // default scroll to top value, components/_scroll_to_top.scss
      back_top_distance: -50, // how far from top of page before showing "top" widget
      mq_state: {
        small_only: 'only screen and (max-width: 640px)',
        medium_only: 'only screen and (min-width:641px) and (max-width: 768px)',
        ipad_only_portrait: 'only screen and (min-width:768px) and (max-width: 1024px) and (orientation: portrait)',
        ipad_only_landscape: 'only screen and (min-width:768px) and (max-width: 1024px) and (orientation: landscape)',
        tablet_only_portrait: 'only screen and (min-width:800px) and (max-width: 1280px) and (orientation: portrait)',
        tablet_only_landscape: 'only screen and (min-width:800px) and (max-width: 1280px) and (orientation: landscape)'
      },
      nav_open: false,
      search_open: false,
      // we often need to get the header height for other calculations
      header_height: function() {
        // set a variable value here instead of constant lookup
        return $('#toolbar').height() + $('.page-header').outerHeight();
      },
      // we often need to get the sticky footer height for other calculations
      sticky_footer_height: function() {
        return $('.page-sticky-footer').height();
      },
      // Resize an element to the usable height of the window,
      // see home_formatter.js for implementation
      fullHeightElement: function($element, includeHeader) {
        // scope stickyFooterHeight
        var stickyFooterHeight = this.sticky_footer_height(),
          headerHeight = this.header_height();
        // return a throttle-able function (with args!) ready to be used in a .resize
        return _.throttle(function() {
          // start with window height, subtract admin toolbar height
          var windowHeight = $(window).height(),
            realWindowHeight = windowHeight - stickyFooterHeight,
            // minHeight = 700;
            minHeight = 650;
          if (includeHeader) {
            realWindowHeight = realWindowHeight - headerHeight;
          }
          // Set element to safe height unless it's below minHeight
          $element.height((realWindowHeight >= minHeight ? realWindowHeight : minHeight));
        }, 250);
      } // fullHeightElement()
    }, // ui
    getJSONCookie : function(cookieName) {
      return jQuery.parseJSON($.cookie(cookieName) || '{}');
    },
    // Initiate all selectboxes
    applySelectBoxes: function() {
      $('select.selectbox, select.selectBox').selectBox().removeClass('selectbox');
    },
    // selectbox.js doesn't account for iOS when refreshing even though it does
    // when initing. Use this wrapper function instead of using .selectBox('refresh')
    // directly
    refreshSelectBoxes : function($selectBoxes) {
      $selectBoxes.each(function(){
        var control = $(this).data('selectBox-control');
        if (control && control.length) {
          $(this).selectBox('refresh');
        }
      });
    },
    waypointBottomOffset : function(element) {
      var $element = $(element);
      var contextHeight = $(window).height();
      if( $element.outerHeight() >  contextHeight){
        contextHeight = $element.outerHeight() - 200; //200 for minibag offset
      }
      return contextHeight - $element.outerHeight() - 200;
    },
    //
    // Helper function to return TRUE or FALSE based on whether the personalization engine is enabled.
    // We know this to be true or not based on personal_block being loaded in Drupal.settings.
    //
    hasPersonalization: function() {
      var settings        = Drupal || {};
          settings        = settings.settings || {};
          personalization = settings.personal_block;
      return personalization ? 1 : 0;
    },
    loadPersistenUserCookie: function() {
      var cookieName = 'persistent_user_cookie';
      var cookieVal = Drupal.ELB.getJSONCookie(cookieName);
      // These are the keys we want to copy out of FE_USER_CART:
      var cookieKeys = ['first_name', 'pc_email_optin', 'email', 'is_loyalty_member', 'points', 'loyalty_level', 'loyalty_level_name', 'points_to_next_level', 'next_level', 'next_level_name'];
      // Share accross desktop and mobile sites:
      var domain = '.' + window.location.hostname.replace(
        /^.*(clinique\.)/,
        function(m, $1) { return $1; }
      );

      // The jquery.cookie.js included in Drupal is from before this feature
      // existed :(
      // $.cookie.json = true;

      // User only gets one first time:
      if ($.isEmptyObject(cookieVal)) {
        cookieVal.first_time = 1;
      } else {
        cookieVal.first_time = 0;
      }

      // Prevent errors if user_info_cookie.js isn't found:
      if (typeof site != 'undefined' && typeof site.userInfoCookie != 'undefined') {
        // FE_USER_CART is not persistent, it gets emptied when the user signs
        // out. If the values in it update, we want to update our persistent
        // cookie as well, but if they're null we want what's in our cookie to
        // persist.
        var key, val;
        for (var i = 0; i < cookieKeys.length; i++) {
          key = cookieKeys[i];
          val = site.userInfoCookie.getValue(key);
          cookieVal[key] = val;
        }
      }

      $.cookie(cookieName, JSON.stringify(cookieVal), { expires: 365 * 5, path: '/', domain: domain, secure: true, SameSite: 'Lax' });

      if(cookieVal.is_loyalty_member - 0) {
        // set global loyalty class for when user is enlisted in loyalty
        // used to controll footer state
        $('body').addClass('elc-user-state-loyalty');
      }else{
        // make sure class is not there for non-loyal
        $('body').removeClass('elc-user-state-loyalty');
      }

    }
  }; // Drupal.ELB

  // init page_data
  var page_data = page_data || {};
  var isMobile = $('body').hasClass('device-mobile');

  Drupal.behaviors.ELB_userState = {
    attach: function(context, settings) {
      var signedIn = typeof site != 'undefined';
      signedIn = signedIn && typeof site.userInfoCookie != 'undefined';
      signedIn = signedIn && parseInt(site.userInfoCookie.getValue('signed_in'), 10);
      var signInState = signedIn ? 'logged-in' : 'anonymous';
      $('body').addClass( 'elc-user-state-' + signInState );
    }
  };

  Drupal.behaviors.ELB_persistentUserCookie = {
    attach: function(context, settings) {
      Drupal.ELB.loadPersistenUserCookie();
    }
  };

  if (isMobile) {
    $(document).on('sku:select', function(e, skuData) {
      var prod_id = skuData.PRODUCT_ID;
      var sku_id = skuData.SKU_BASE_ID;
      var $images = $('.sku_select.selectbox[data-productid="' + prod_id + '"]', $('.product-list')).parents('li[data-productid]');
      var $sku_img = $('img[data-skuid="SKU' + sku_id + '"]');
      if ($sku_img.length > 0) {
        $('.spp-product .product-thumb img, .mpp_product .product-thumb img', $images).attr('src', $sku_img.attr('data-src'));
      }
    });
  }

})(jQuery);

// Adds User Agent string as data attribute on <html> needed mainly for IE8 ~ http://css-tricks.com/ie-10-specific-styles/
var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);
